import React from 'react';
import './MyGoals.css';

const MyGoals = ({ goals }) => {
    if (!Array.isArray(goals) || goals.length === 0) {
        return <div></div>
    }

    return (
        <div className="MyGoals">
            <h2>My Goals</h2>
            <div className="GoalsList">
                {goals.map((goal, index) => {
                    return (
                        <div key={index} className="Goal">
                            <h3>{goal.title}</h3>
                            <p>{goal.description}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default MyGoals;