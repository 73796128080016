import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import PulseLoader from 'react-spinners/PulseLoader';
import { isValid, addDays, startOfWeek, endOfWeek, startOfMonth } from "date-fns";
import {firestoreManager} from '../../Firebase/FirestoreManager';
import MyGoals from "../../Goals/MyGoals";
import './SummaryandPlan.css'

const SummaryAndPlan = ({ user, selectedModel, entries, handleDiscussWithQuilly }) => {
    const [summary, setSummary] = useState("");
    const [summaries, setSummaries] = useState([]);
    const [summaryForGoals, setSummaryForGoals] = useState("");
	const sanitizedSummary = DOMPurify.sanitize(summary);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [showSummary, setShowSummary] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [goalsAreLoading, setGoalsAreLoading] = useState(false);
    const [goalsLoadingSummaryId, setGoalsLoadingSummaryId] = useState(null);
    const [summaryIsLoading, setSummaryIsLoading] = useState(false);
    const [planIsLoading, setPlanIsLoading] = useState(false);
    const [discussIsLoading, setDiscussIsLoading] = useState(false);
    const [discussingEntryId, setDiscussingEntryId] = useState(null);
    const [userInput, setUserInput] = useState('');
    const [goalsForSummaries, setGoalsForSummaries] = useState([]);
    const [goalsVisibility, setGoalsVisibility] = useState({});

    useEffect(() => {
        const loadSummaries = async () => {
            if (user?.uid) {
                const data = await firestoreManager.get_summaries(user?.uid);

                // Order chronologically reversed
                const sortedData = data.sort((a, b) => b.date_start - a.date_start);
                setSummaries(sortedData);

                // Load goals for each summary
                const goalsData = {};
                for (const summary of sortedData) {
                    const goals = await firestoreManager.get_goals(summary.id, user?.uid);
                    goalsData[summary.id] = goals;
                }
                setGoalsForSummaries(goalsData);
            }
        };

        loadSummaries();
    }, [user?.uid]);

    const handleViewSummary = async (summary) => {
        setSummary(summary.summary);
        toggleSummary();
    }

    const handleGenerateGoals = async (summary) => {
        setGoalsAreLoading(true);
        try {
            const response = await fetch('https://aspireai-418902.ue.r.appspot.com/goals/weekly', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ summary: summary, modelType: selectedModel }),
            });
            if (response.ok) {
                const data = await response.json();
                const goalsString = data.goals; // This is a string
                const parsedGoals = JSON.parse(goalsString); // Parse the string into JSON
                const goalsData = parsedGoals.goals; // Now we access the 'goals' array
                if (Array.isArray(goalsData)) {
                    // Save the goals to Firestore
                    for (const goal of goalsData) {
                        const goalTitle = Object.values(goal)[0];
                        const goalDescription = goal.description;
                        await firestoreManager.create_goal(user?.uid, summary.id, 'weekly', goalTitle, goalDescription);
                    }
                    // Fetch the saved goals from Firestore
                    const savedGoals = await firestoreManager.get_goals(summary.id, user?.uid);
                    setGoalsForSummaries(prev => ({
                        ...prev,
                        [summary.id]: savedGoals.map(goal => ({ ...goal, visible: true })),
                    }));
                    setGoalsVisibility(prev => ({
                        ...prev,
                        [summary.id]: true,
                    }));
                } else {
                    console.error('Parsed goals data is not an array:', goalsData);
                }
            } else {
                console.error('Error fetching goals:', response.status);
            }
        } catch (error) {
            console.error('Error fetching and parsing goals:', error);
        } finally {
            setGoalsAreLoading(false);
        }
    };

    async function checkIfWeeklySummaryExists() {
        const currentDate = new Date();
        const weekStart = startOfWeek(currentDate);
        const weekEnd = endOfWeek(currentDate);

        // Check if a summary already exists for the current week
        const summaries = firestoreManager.get_summaries(user?.uid)
        const existingSummaryQuery = await summaries
            .where('date_start', '==', weekStart)
            .where('date_end', '==', weekEnd)
            .get();

        return !existingSummaryQuery.empty
    }

    async function createMonthlySummaryIfNotExists() {
        const currentDate = new Date();
        const monthStart = startOfMonth(currentDate);

        // Check if a summary already exists for the current month
        const summaries = firestoreManager.get_summaries(user?.uid)
        const existingSummaryQuery = await summaries
            .where('date_start', '==', monthStart)
            .get();

        if (existingSummaryQuery.empty) {
            // Create a new summary for the month
            const summaryData = {
                user_id: 'user_id_here',
                date_start: monthStart,
                // Additional fields for the summary
            };

            await summaries.add(summaryData);
            console.log('Monthly summary created.');
        } else {
            console.log('Monthly summary already exists.');
        }
    }

    const filterEntriesByDate = (entries, startDate, endDate) => {
            return entries.filter((entry) => {
                const entryDate = entry.created_at.toDate();
                return (
                    entryDate <= endDate && entryDate >= startDate
                    );
            });
        };

    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // TODO: Implement chat functionality with Quilly
        setUserInput('');
    };

        const toggleSummary = () => {
            setShowSummary(!showSummary);
        };

        const generateSummary = async (runDate, isMonthly = false) => {
            setIsLoading(true);

            const filteredEntries = filterEntriesByDate(entries, startDate, endDate);

            try {
                const response = await fetch('https://aspireai-418902.ue.r.appspot.com/trends/summary', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        entries: filteredEntries,
                        startDate: startDate.toISOString(),
                        endDate: endDate.toISOString(),
                        modelType: selectedModel
                    }),
                });

                const result = await response.json();
                const formattedSummary = result.summary
                    .replace(/\*\*(.+?)\*\*(?=\n|$)/g, '<strong>$1</strong>') // Bold '**text**' correctly
                    .replace(/- /g, "<li>") // Replace "- " with "<li>"
                    .replace(/<\/li>(?=\s*<li>)/g, "") // Correctly wrap adjacent <li> tags
                    .replace(/(\s*<li>[^<]+<\/li>)/g, "<ul>$1</ul>") // Wrap <li> blocks in <ul> tags
                    .replace(/<li>(\d+\..+?)<\/li>/g, "<li>$1</li>") // Adjust numbered list items
                    .replace(/(<li>\d+.+?<\/li>)/g, "<ol>$1</ol>") // Wrap <li> blocks in <ol> tags
                    .replace(/\*\*\*(.+?)\*\*\*(?=\n|$)/g, '<h3>$1</h3>') // Capture "### Heading"
                    .replace(/\*\*(.+?)\*\*(?=\n|$)/g, '<h2>$1</h2>') // Capture "## Heading"
                    .replace(/\n/g, '<br>'); // Replace remaining newlines with <br>

                setSummary(formattedSummary);
                await firestoreManager.create_summary(user?.uid, startDate, endDate, formattedSummary, false, filteredEntries.map(obj => obj.id));
                return result.summary;
            } catch (error) {
                console.error('Error generating summary:', error);
                // Handle error appropriately
            } finally {
                setIsLoading(false);
            }
        }

    return (
        <div className="SummaryPlanContainer">
            <div className="DatePickerContainer">
                <input
                    className="DatePicker"
                    type="date"
                    value={startDate.toISOString().substring(0, 10)}
                    onChange={(e) => setStartDate(new Date(e.target.value))}
                />
                <input
                    className="DatePicker"
                    type="date"
                    value={endDate.toISOString().substring(0, 10)}
                    onChange={(e) => setEndDate(new Date(e.target.value))}
                />
                <div className="GenerateButtonWrapper">
                    {isLoading ? (
                        <div className="GenerateButtonOverlay loading">
                            <PulseLoader color="white" />
                        </div>
                        ) : null}
                    <button className="GenerateButton" onClick={generateSummary}>Generate
                        Summary</button>
                </div>
            </div>
            {summaries.map((summary, index) => (
                <div className="EntryCard" key={index}>
                    <div className="EntryHeader">{summary.date_start.toDate().toLocaleString()}</div>
                    <div className="EntryContent">{summary.summary}</div>
                    <div className="EntryMetaData">
                        <div>Entries in Summary: {summary.entries.join(', ')}</div>
                    </div>
                    <div className="GenerateButtonWrapper">
                        {goalsLoadingSummaryId === summary.id && goalsAreLoading ? (
                            <div className="GenerateButtonOverlay loading">
                                <PulseLoader color="white" />
                            </div>
                            ) : null}
                        <button
                            className="ViewDetailsButton"
                            onClick={() => {
                                if (goalsForSummaries[summary.id] && goalsForSummaries[summary.id].length > 0) {
                                    // If goals exist, toggle the visibility of the goals section
                                    setGoalsVisibility(prev => ({
                                        ...prev,
                                        [summary.id]: !prev[summary.id],
                                    }));
                                } else {
                                    // If goals don't exist, generate new goals
                                    setGoalsLoadingSummaryId(summary.id);
                                    handleGenerateGoals(summary);
                                }
                            }}
                        >
                        {goalsForSummaries[summary.id] && goalsForSummaries[summary.id].length > 0
                            ? goalsVisibility[summary.id]
                                ? 'Hide Goals'
                                : 'View Goals'
                            : 'Generate Goals'}
                    </button>
                    </div>
                    <button className="ViewDetailsButton" onClick={() => handleViewSummary(summary)}>
                    {showSummary ? "Hide Summary" : "Show Summary"}
                    </button>
                    {sanitizedSummary && showSummary &&(
                        <div className="Content">
                            <div className="SummarySection">
                                <h3>Summary</h3>
                                <div dangerouslySetInnerHTML={{__html: sanitizedSummary}}></div>
                            </div>
                        </div>
                    )}
                    {goalsVisibility[summary.id] && (
                        <MyGoals goals={goalsForSummaries[summary.id]} />
                    )}
                    <div className="ChatContainer">
                        <h3>Chat with Quilly about your Week</h3>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                value={userInput}
                                onChange={handleInputChange}
                                placeholder="Type your message..."
                            />
                            <button type="submit">Send</button>
                        </form>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SummaryAndPlan;
