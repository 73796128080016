import React, { useState, useEffect, useRef } from 'react';
import RiseLoader from "react-spinners/RiseLoader";
import './ResponsePane.css';
import quillyAvatar from '../Images/avatar_quilly.png';
import userAvatar from '../Images/avatar_user_male.png'


const ResponsePane = ({ responses, isLoading, isAnalyzing, onUserSubmit }) => {
    const [userInput, setUserInput] = useState('');
    const messagesEndRef = useRef(null);
    const [expandedMessages, setExpandedMessages] = useState(new Set());

    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    useEffect(() => {
        // When responses are updated, ensure the latest message is expanded
        setExpandedMessages(new Set(responses.map((_, index) => index)));
        }, [responses]);    

    const toggleMessage = (index) => {
        const newExpandedMessages = new Set(expandedMessages);
        if (newExpandedMessages.has(index)) {
            newExpandedMessages.delete(index);
        } else {
            newExpandedMessages.add(index);
        }
        setExpandedMessages(newExpandedMessages);
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
        }, [responses, isLoading, isAnalyzing]);

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        onUserSubmit(userInput); // Call the passed function with the current state value
        setUserInput(''); // Clear the input after submitting
    };

    // Conditional rendering based on isLoading and isAnalyzing
    return (
        <div className="Responses">
            {/* Render previous responses */}
            {responses.filter(response => response.message).map((response, index) => (
                <div key={index}
                     className={`ResponseBubble ${response.type === 'quilly' ? 'QuillyBubble' : 'UserBubble'}`}
                     onClick={() => toggleMessage(index)}>
                    <img src={response.type === 'quilly' ? quillyAvatar : userAvatar} alt={response.type}
                         className={response.type === 'quilly' ? 'QuillyAvatar' : 'UserAvatar'}/>
                    <div className="ResponseContent">
                        <div
                            className="ResponseMessage">{expandedMessages.has(index) ? response.message : `${response.message.substring(0, 100)}...`}</div>
                        <div className="ExpandCollapseIcon">
                            {expandedMessages.has(index) ? '▲' : '▼'}
                        </div>
                    </div>
                </div>
            ))}
            {/* Automatic scroll to this div when new message is added */}
            <div ref={messagesEndRef}/>
            {/* Render a spinner if awaiting greeting (isLoading) or analysis response (isAnalyzing) */}
            {(isLoading || isAnalyzing) && (
                <div className="ResponseBubble">
                    <img src={quillyAvatar} alt="Quilly" className="QuillyAvatar"/>
                    <div className="ResponseLoader">
                        <RiseLoader color="#e7f5ff" size={10}/>
                    </div>
                </div>
            )}
            <div className="ChatInputContainer">
                <form onSubmit={handleSubmit} className="ChatInputForm">
                    <input
                        type="text"
                        value={userInput}
                        onChange={handleInputChange}
                        placeholder="Chat with Quilly..."
                        className="ChatInput"
                    />
                    <button type="submit" className="ChatSubmitButton">Send</button>
                </form>
            </div>
        </div>
    );
};

export default ResponsePane;