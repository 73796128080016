import React, { useState, useEffect } from 'react';
import FirebaseAuthentication from './FirebaseAuthentication';
import './SignIn.css'
import {GoogleAuthProvider} from "firebase/auth";

const SignIn = ({ theme = 'dark' }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    // Handles the submission of the form for logging in
    const handleLoginSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submit action
        try {
            await FirebaseAuthentication.signIn(email, password);
            // Reset error state in case of previous errors
            setError('');
            // Add any action after successful login, such as redirecting to another page
        } catch (error) {
            setError(error.message); // Set error message to be displayed
            console.error("Login failed:", error.message);
        }
    };

    const handleRegistration = async () => {
        try {
            await FirebaseAuthentication.signUp(email, password);
            setError(''); // Clear any previous errors on successful registration
        } catch (error) {
            setError(error.message); // Set error message to be displayed
            console.error("Registration failed:", error.message);
        }
    };

    return (
        <div className={`signInScreen ${theme}`}>
            <div className="signInCard">
                <h2>Welcome to AspireAI</h2>
                {error && <p className="error">{error}</p>}
                <form onSubmit={handleLoginSubmit}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit" className="signInButton">Sign In</button>
                    <SignInWithGoogleButton />
                    <button type="button" onClick={handleRegistration} className="registerButton">Register</button>
                </form>
            </div>
        </div>
        );
};

const SignInWithGoogleButton = () => {
    const handleGoogleSignIn = async () => {
        try {
            const result = await FirebaseAuthentication.signInWithGoogle();
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = result.credential;
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            // ...
        } catch (error) {
            // Handle Errors here.
            console.error("Google sign-in failed:", error);
        }
    };

    return (
        <button onClick={handleGoogleSignIn} className="signInButton">
            Sign in with Google
        </button>
        );
};

export default SignIn;
