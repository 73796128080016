import React, { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { firestoreManager } from '../Firebase/FirestoreManager';
import './JournalEntry.css'

function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

const JournalPane = ({ userId, onSendEntry, setEntryId, entry, setEntry, isDiscussion }) => {
    const [lastSentCount, setLastSentCount] = useState(0);

    // Effect hook to create a new journal entry on component mount
    useEffect(() => {
        const createEntry = async () => {
            if (userId) {
                try {
                    const docRef = uuidv4();
                    console.log("Created entry ID:", docRef);
                    setEntryId(docRef);
                } catch (error) {
                    console.error("Failed to create entry:", error);
                }
            } else {
                console.error("User not logged in");
            }
        };

        createEntry();
        }, [userId]);

    const debouncedUpdateEntry = useCallback(
        debounce(async (entryId, newEntry) => {
            if (entryId) {
                await firestoreManager.updateEntry(entryId, newEntry);
                console.log("Entry updated.");
            }
        }, 10), // 1000ms delay for debouncing
        []
        );

    const handleTextChange = async (event) => {
        const newEntry = event.target.value;
        setEntry(newEntry); // Update the App component's state
        const charCountDifference = newEntry.length - lastSentCount;

        // Update the journal entry in Firestore every 500 characters
        if (charCountDifference >= 500) {
            setLastSentCount(newEntry.length); // Update the last sent count
            onSendEntry(newEntry); // Trigger any side effects if needed
        }
    };

    const handleAnalyzeClick = () => {
        // Trigger the analysis of the current journal entry
        onSendEntry(entry);

        // Reset the character count to prevent automatic trigger after manual analysis
        setLastSentCount(entry.length);
    };

    return (
        <>
        <textarea
            value={entry}
            onChange={handleTextChange}
            placeholder="Hey journal..."
            className="JournalInput"
        />
        {!isDiscussion && (
            <button onClick={handleAnalyzeClick} className="AnalyzeButton">
                🦉Call Quilly🦉
            </button>
            )}
        </>
        )
}

export default JournalPane;
