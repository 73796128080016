import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import './charts.css'

const EntryAnalysisPieChart = ({ sentimentData, width, height }) => {
  if (!sentimentData || !Array.isArray(sentimentData) || sentimentData.length === 0) {
    return <div>Loading chart data...</div>; // or any other fallback UI
  }
  
  const COLORS = ['#104047', '#470140', '#470801', '#01472b'];

  return (
    <PieChart className="emotion-chart" width={width} height={height}>
      <Pie
        data={sentimentData}
        cx="50%"
        cy="50%"
        labelLine={false}
        outerRadius={120}
        fill="#8884d8"
          dataKey="value"
          nameKey="name"
          >
        {sentimentData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
      </Pie>
        <Tooltip />
        <Legend />
    </PieChart>
    );
};

export default EntryAnalysisPieChart;
