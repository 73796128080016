import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

const EntryAnalysisBarChart = ({ analysisData, width, height }) => {
    const data = analysisData.map(item => ({
        emotion: item.name, 
        count: item.value, 
    }))
    .filter(item => item.count > 1);

    return (
        <BarChart width={width} height={height} data={data}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="emotion"/>
            <YAxis/>
            <Tooltip/>
            <Legend/>
            <Bar dataKey="count" fill="#014047" display="emotions ocurring more than once"/>
        </BarChart>
    );
}

export default EntryAnalysisBarChart;