import { firestoreManager } from '../Firebase/FirestoreManager';

class AnalysisManager {
    async performSentimentAnalysis (model_type, entry, entryId) {
        try {
            const response = await fetch('https://aspireai-418902.ue.r.appspot.com/entry/analyze/sentiment', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({entry, modelType: model_type}),
            });

            const data = await response.json();
            if (response.ok) {
                await firestoreManager.updateEntrySentiment(entryId, data.analysis)
            } else {
                console.error('Error from server', data);
            }
        } catch (error) {
            console.error('Error sending entry', error);
        } 
    }
    
    async performEmotionAnalysis (model_type, entry, entryId) {
        try {
            const response = await fetch('https://aspireai-418902.ue.r.appspot.com/entry/analyze/emotion', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({entry, modelType: model_type}),
            });

            const data = await response.json();
            if (response.ok) {
                await firestoreManager.updateEntryEmotions(entryId, data.analysis)
            } else {
                console.error('Error from server', data);
            }
        } catch (error) {
            console.error('Error sending entry', error);
        } 
    }
    
    async performTopicAnalysis (model_type, entry, entryId) {
        try {
            const response = await fetch('https://aspireai-418902.ue.r.appspot.com/entry/analyze/topic', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({entry, modelType: model_type}),
            });

            const data = await response.json();
            if (response.ok) {
                await firestoreManager.updateEntryTopics(entryId, data.analysis)
            } else {
                console.error('Error from server', data);
            }
        } catch (error) {
            console.error('Error sending entry', error);
        } 
    }
}

export const analysisManager = new AnalysisManager()