// firebase-config.js
import {initializeApp} from "firebase/app";
import {
    getAuth,
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signOut,
    signInWithPopup,
    onAuthStateChanged
} from "firebase/auth";

const firebaseConfig = {
    //REPLACE WITH THE ONE FROM CONFIG FILE
    apiKey: "AIzaSyA6HzavwhF_eSpznohU-RR84CSvAdlwP7w",
    authDomain: "aspireai-e1a4d.firebaseapp.com",
    projectId: "aspireai-e1a4d",
    storageBucket: "aspireai-e1a4d.appspot.com",
    messagingSenderId: "105864434761",
    appId: "1:105864434761:web:86912c7a5942cf1e6e1b98",
    measurementId: "G-4P627C02F0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);


class FirebaseAuthentication {
    // Sign up with email and password
    static signUp(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    // Sign in with email and password
    static signIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    static provider = new GoogleAuthProvider();
    static auth = getAuth();
    static signInWithGoogle() {
        return signInWithPopup(this.auth, this.provider);
    }

    // Sign out
    static signOut() {
        const auth = getAuth();
        return signOut(auth);
    }

    // Listen for auth state changes
    static onAuthStateChanged(callback) {
        onAuthStateChanged(auth, callback);
    }
}

export default FirebaseAuthentication;
