import React, {useState, useEffect} from 'react';
import {firestoreManager} from '../Firebase/FirestoreManager';
import { isValid } from "date-fns";
import PulseLoader from 'react-spinners/PulseLoader';
import { toast, ToastContainer, Bounce } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import EntryAnalysisPieChart from "../Charts/EntryAnalysisPieChart";
import EntryAnalysisLineChart from "../Charts/EntryAnalysisLineChart";
import EntryAnalysisBarChart from "../Charts/EntryAnalysisBarChart";
import SummaryAndPlan from "./Trends/SummaryandPlan";
import './Journey.css'

const Journey = ({ user, selectedModel, handleDiscussWithQuilly }) => {
    const [entries, setEntries] = useState([]);
    const [activeTab, setActiveTab] = useState('entries');
    const [discussIsLoading, setDiscussIsLoading] = useState(false);
    const [discussingEntryId, setDiscussingEntryId] = useState(null);

    useEffect(() => {
        const loadEntries = async () => {
            if (user?.uid) {       
                const data = await firestoreManager.getUserEntries(user?.uid);

                //Order chronologically reversed
                const sortedData = data.sort((a, b) => b.created_at - a.created_at);
                setEntries(sortedData);
            }
        };

        loadEntries();
        }, [user?.uid]);

    const switchTab = (tabName) => {
        setActiveTab(tabName);
    };

    const sentimentData = entries.map(entry => entry.sentiment.toLowerCase())
    const sentimentCounts = sentimentData.reduce((acc, entry) => {
        acc[entry.toLowerCase()] = (acc[entry.toLowerCase()] || 0) + 1;
        return acc;
        }, {});

    const sentimentDataForChart = Object.entries(sentimentCounts).map(([sentiment, count]) => ({
        name: sentiment,
        value: count,
    }));

    const emotionCounts = entries.reduce((acc, entry) => {
        if (Array.isArray(entry.emotions)) {
            entry.emotions.forEach(emotion => {
                // Normalize the emotion string: trim whitespace and convert to lower case
                const normalizedEmotion = emotion.trim().toLowerCase();

                // Increment the count for the normalized emotion in the accumulator object
                acc[normalizedEmotion] = (acc[normalizedEmotion] || 0) + 1;
            });
        }
        return acc;
        }, {});

    const emotionDataForChart = Object.entries(emotionCounts).map(([emotion, count]) => ({
        name: emotion,
        value: count,
    }));

    return (
        <div className="Journey">
            <div className="TabsContainer">
                <button
                    className={`Tab ${activeTab === 'entries' ? 'TabActive' : ''}`}
                    onClick={() => switchTab('entries')}
                >
                    Journal Entries
                </button>
                <button
                    className={`Tab ${activeTab === 'summaries' ? 'TabActive' : ''}`}
                    onClick={() => switchTab('summaries')}
                >
                    Summaries
                </button>
                <button
                    className={`Tab ${activeTab === 'charts' ? 'TabActive' : ''}`}
                    onClick={() => switchTab('charts')}
                >
                    Charts
                </button>
            </div>
            <div className="TabsContent">
                {activeTab === 'entries' && (
                    <div className="TabContent">
                        {entries
                            .filter(entry =>
                                entry.content && isValid(entry.created_at.toDate()) && isValid(entry.updated_at.toDate())
                            )
                            .map((entry, index) => (
                                <div className="EntryCard" key={index}>
                                    <div className="EntryHeader">{entry.created_at.toDate().toLocaleString()}</div>
                                    <div className="EntryContent">{entry.content}</div>
                                    <div className="EntryMetaData">
                                        <div>Created at: {entry.created_at.toDate().toLocaleString()}</div>
                                        <div>Updated at: {entry.updated_at.toDate().toLocaleString()}</div>
                                        <div>Emotions: {entry.emotions.join(', ')}</div>
                                        <div>Topics: {entry.topics.join(', ')}</div>
                                        <div>Sentiment: {entry.sentiment}</div>
                                    </div>
                                    {discussingEntryId === entry.id && discussIsLoading ? (
                                        <PulseLoader className="Loader" color="#014047"/>
                                    ) : (
                                        <button className="ViewDetailsButton"
                                                onClick={() => {
                                                    setDiscussingEntryId(entry.id); // Assuming you have entry.id to uniquely identify entries
                                                    handleDiscussWithQuilly(entry, setDiscussIsLoading);
                                                }}>Discuss with Quilly</button>
                                    )}
                                </div>
                            ))}

                    </div>
                )}
                {activeTab === 'summaries' && (
                    <div className="TabContent">
                        <SummaryAndPlan user={user} selectedModel={selectedModel} entries={entries} handleDiscussWithQuilly={handleDiscussWithQuilly}/>
                    </div>
                )}
                {activeTab === 'charts' && (
                    <div className="TabContent">
                        <div className="ChartContainer">
                            <div className="ChartWrapper">
                                <EntryAnalysisPieChart sentimentData={sentimentDataForChart} height={400}
                                                       width={300}></EntryAnalysisPieChart>
                            </div>
                            <div className="ChartWrapper">
                                <EntryAnalysisBarChart analysisData={emotionDataForChart} height={400}
                                                       width={600}></EntryAnalysisBarChart>
                            </div>
                            <div className="ChartWrapper">
                                <EntryAnalysisLineChart analysisData={entries} height={400}
                                                        width={600}></EntryAnalysisLineChart>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
};

export default Journey;
