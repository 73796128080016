import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

const EntryAnalysisLineChart = ({ analysisData, width, height }) => {

    // Function to transform data
    const transformDataForChart = (entries) => {
        return entries
            .sort((a, b) => a.created_at.toDate() - b.created_at.toDate())
            .map((entry) => ({
                date: entry.created_at.toDate().toLocaleDateString(),
                sentiment: mapSentimentToValue(entry.sentiment),
            }));
    };

    // Example mapping function for sentiment to numerical value
    const mapSentimentToValue = (sentiment) => {
        const mapping = {
            positive: -1,
            neutral: 0,
            negative: 1,
        };
        return mapping[sentiment.toLowerCase()] || 0;
    };

    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            const sentimentValue = payload[0].value;
            let sentimentLabel;
            switch (sentimentValue) {
                case -1:
                    sentimentLabel = 'Negative';
                    break;
                case 0:
                    sentimentLabel = 'Neutral';
                    break;
                case 1:
                    sentimentLabel = 'Positive';
                    break;
                default:
                    sentimentLabel = 'Unknown';
            }

            return (
                <div className="custom-tooltip">
                    <p className="label">{`${label} : ${sentimentLabel}`}</p>
                </div>
            );
        }

        return null;
    };

    // Call this function where you have your entries state
    const dataForChart = transformDataForChart(analysisData);
    
    return (
        <ResponsiveContainer width={width} height={height}>
            <LineChart
                data={dataForChart}
                margin={{
                top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis domain={[-1, 1]}/>
                <Tooltip content={<CustomTooltip />}/>
                <Legend />
                <Line
                    type="monotone"
                    dataKey="sentiment"
                    stroke="#014047"
                    activeDot={{ r: 8 }}
                />
            </LineChart>
        </ResponsiveContainer>
    )
};

export default EntryAnalysisLineChart;
