import React from 'react';
import { useLocation } from 'react-router-dom';

const TitleBar = ({ username }) => {
    const location = useLocation();

    // Function to determine the title based on the current pathname
    const determineTitle = (pathname) => {
        switch (pathname) {
            case '/':
                return `AspireAI - ${username}'s Journal`;
            case '/journey':
                return `AspireAI - ${username}'s Journey`;
            case '/goals':
                return `AspireAI - ${username}'s Goals`;
            case '/settings':
                return 'Settings';
            // Add more cases as needed
            default:
                return 'AspireAI';
        }
    };

    return (
        <div className="TitleBar">
            {determineTitle(location.pathname)}
        </div>
        );
};

export default TitleBar;
